import React, {Fragment} from 'react'
import ButtonsContainer from "../Buttons/ButtonsContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import InfoGarage from "./InfoGarage/InfoGarage";
import CorrespondantsContainer from "./Correspondants/CorrespondantsContainer";
import {openInWindow} from "../../../../../constants/constants";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import ChequesCadeauxContainer from "../Courtier/ChequesCadeaux/ChequesCadeauxContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import _ from "lodash";
import ComptePaddockContainer from "../Courtier/ComptePaddock/ComptePaddockContainer";
import CommerciauxContainer from "../Courtier/Commerciaux/CommerciauxContainer";

const Garage = (props) => {
    const {tiers, roles, postUpdateTiersApporteur, query, querySyncApporteur, postSyncApporteur} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>
                        {tiers.belair_id} - {tiers.titre} {tiers.name}
                    </h1>
                    {!_.includes(roles, 'gestionnaire televente') && (
                        <span
                            className={'btn btn-cta btn-action float-right'}
                            onClick={(e) =>
                                redirectInNewWindow({
                                    e,
                                    url: `/les-activites/tiers/${tiers.belair_id}`,
                                    name: openInWindow.activitiesTiers,
                                })
                            }
                        >
                            Les activités du tiers
                        </span>
                    )}
                </div>
                <div className={'col-12 mt-3'}>
                    <ButtonsContainer tiers={tiers} />
                    <button
                        className={'btn btn-cta btn-success mt-3 ml-0'}
                        onClick={() => {
                            if (window.confirm('Etes vous sûr de vouloir synchroniser les données avec Synergie ?'))
                                postSyncApporteur({
                                    query: _.replace(querySyncApporteur, '{id}', tiers.belair_id),
                                });
                        }}
                    >
                        Synchroniser Synergie
                    </button>

                    {_.includes(roles, 'gestionnaire commercial') && (
                        <Fragment>
                            {tiers.stadeGIE === 'CL' ? (
                                <button
                                    className={'btn btn-cta btn-success mt-3 ml-2'}
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                'Attention, il faut penser à vérifier les données Salesforce ou la mise à jour automatique avant de réactiver l’apporteur manuellement',
                                            )
                                        )
                                            postUpdateTiersApporteur({
                                                query: _.replace(query, '{id}', tiers.belair_id),
                                                form: { stadeGIE: 'VA' },
                                            });
                                    }}
                                >
                                    Ouvrir la fiche apporteur
                                </button>
                            ) : (
                                <button
                                    className={'btn btn-cta btn-danger mt-3 ml-2'}
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                'Attention, il faut penser à vérifier les données Salesforce ou la mise à jour automatique avant de clôturer l’apporteur manuellement',
                                            )
                                        )
                                            postUpdateTiersApporteur({
                                                query: _.replace(query, '{id}', tiers.belair_id),
                                                form: { stadeGIE: 'CL' },
                                            });
                                    }}
                                >
                                    Clôturer la fiche apporteur
                                </button>
                            )}

                            <button
                                className={'btn btn-cta btn-ternary mt-3 ml-2'}
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            `Attention, en ${tiers.lock_update ? 'débloquant' : 'bloquant'} la MAJ Auto, les données de Salesforce ${tiers.lock_update ? 'mettront à jour la fiche apporteur automatiquement' : 'ne mettront plus à jour la fiche apporteur'}`,
                                        )
                                    ) {
                                        postUpdateTiersApporteur({
                                            query: _.replace(query, '{id}', tiers.belair_id),
                                            form: { lock_update: !tiers.lock_update },
                                        });
                                    }
                                }}
                            >
                                {tiers.lock_update ? 'Déblocage MAJ Auto' : 'Blocage MAJ Auto'}
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>
            <div className="row margin-top">
                <div className="col-12">
                    <ComptePaddockContainer />
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col-6'}>
                    <InfoGarage tiers={tiers} />
                </div>
                <div className={'col-6'}>
                    <CommerciauxContainer id={tiers.belair_id} className={'loader'} />
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <CorrespondantsContainer className={'loader'} id={tiers.belair_id} />
                </div>
            </div>
            <div className="row margin-top">
                <div className="col">
                    <ReclamationsContainer className={'loader'} id={tiers.belair_id} />
                </div>
            </div>
            {
                tiers.type !== "R" && <div className={'row margin-top'}>
                    <div className={'col'}>
                        <ChequesCadeauxContainer id={tiers.belair_id} />
                    </div>
                </div>
            }
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <AccidentsContainer className={'loader'} />
                </div>
            </div>
        </Fragment>
    );
}

export default Garage
