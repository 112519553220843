import _ from 'lodash';
import { Field } from 'redux-form';
import RadioFieldRedux from '../../../../../Commun/Input/Radio/RadioFieldRedux';
import Spinner from '../../../../../Commun/Spinner/Spinner';
import FormAvenantSimpleCiviliteContainer from './FormAvenantSimpleCivilite/FormAvenantSimpleCiviliteContainer';
import FormAvenantSimpleDateEffetContainer from './FormAvenantSimpleDateEffet/FormAvenantSimpleDateEffetContainer';
import FormAvenantInfosClientContainer from './FormAvenantSimpleInfosClient/FormAvenantInfosClientContainer';

const FormAvenantSimple = (props) => {
    const {
        handleSubmit,
        resetForm,
        loading,
        isManagerQualite,
        isManagerEC,
        changeValue,
        defaultValueCodepostalCity,
        typeUpdate,
        contract,
        isNVEI,
        isWakam,
        quittances,
        roles,
    } = props;

    let typeUpdateOptions = [{ value: 'informations', label: 'Informations client' }];

    let showDateEffet = false;
    if (
        (quittances?.find((quittance) => quittance.type === 'Avenant')?.id !== undefined &&
            (_.includes(roles, 'managers') || _.includes(roles, 'manager prod'))) ||
        quittances?.find((quittance) => quittance.type === 'Avenant')?.id === undefined
    ) {
        showDateEffet = true;
    }
    if (showDateEffet) {
        typeUpdateOptions.push({ value: 'dateEffet', label: "Date d'effet" });
    }

    if (!isWakam) {
        typeUpdateOptions.push({ value: 'civilite', label: 'Civilité' });
    }

    return (
        <div>
            {typeUpdate === 'informations' && (
                <div className={'alert alert-warning'}>
                    <div className="p-3">
                        <p>
                            Attention, merci d'utiliser <b>UNIQUEMENT</b> ce formulaire pour corriger les fautes
                            d'orthographe.
                        </p>
                        <p>
                            Seules les données saisies par erreur à la souscription par le client ou le courtier peuvent
                            être modifiées.
                        </p>
                        <br />
                        <p className="text-danger font-weight-bold">
                            Pour tous changements de souscripteur, de conducteur, d’adresse ou de véhicule, merci de
                            bien utiliser le formulaire d’avenant.
                        </p>
                    </div>
                </div>
            )}

            <form className={`form-content`} onSubmit={handleSubmit}>
                <Field
                    name={'typeUpdate'}
                    component={RadioFieldRedux}
                    label={<span className={'font-weight-bold'}>Choisissez un type de modification :</span>}
                    data={typeUpdateOptions}
                    onChange={resetForm}
                />

                {typeUpdate === 'informations' && (
                    <FormAvenantInfosClientContainer
                        isWakam={isWakam}
                        changeValue={changeValue}
                        defaultValueCodepostalCity={defaultValueCodepostalCity}
                        isManagerQualite={isManagerQualite}
                        isNVEI={isNVEI}
                    />
                )}

                {typeUpdate === 'dateEffet' && (
                    <FormAvenantSimpleDateEffetContainer
                        changeValue={changeValue}
                        isManagerQualite={isManagerQualite}
                        isManagerEC={isManagerEC}
                        contract={contract}
                    />
                )}
                {typeUpdate === 'civilite' && (
                    <FormAvenantSimpleCiviliteContainer changeValue={changeValue} contract={contract} />
                )}

                {typeUpdate && (
                    <>
                        {loading ? (
                            <Spinner style={{ display: 'block', margin: 'auto' }} />
                        ) : (
                            <button
                                className={'btn btn-primary d-block m-auto'}
                                data-test-id="avenant_simple_btn_submit"
                                type={'submit'}
                            >
                                Mettre à jour{' '}
                                {typeUpdate === 'informations'
                                    ? 'les données du client'
                                    : typeUpdate === 'dateEffet'
                                      ? "la date d'effet"
                                      : 'la civilité'}
                            </button>
                        )}
                    </>
                )}
            </form>
        </div>
    );
};

export default FormAvenantSimple;
