import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {QUITTANCES, getQuittances} from '../../../../../redux/actions/app/quittances/quittances.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Quittances from './Quittances'
import {quittancesGetLink, SendMailPayReceiptLink} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {getQuittancesData} from '../../../../../redux/selectors/quittances/quittances.selectors'
import {CONTRACT} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {PUBLISH_URL} from "../../../../../constants/constants";
import {tokenUser} from "../../../../../redux/selectors/localStorage/localStorage";
import {postPayReceipt} from "../../../../../redux/actions/app/quittances/quittance.actions";
import {logAction} from "../../../../../redux/actions/core/logs/logs.actions";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        quittances: getQuittancesData(state),
        uri: quittancesGetLink(state, CONTRACT),
        loaded: getLoadingEntity(state, QUITTANCES) === false,
        error: getErrorEntity(state, QUITTANCES),
        publish_url: PUBLISH_URL + '?access_token=' + tokenUser,
        uri_pay: SendMailPayReceiptLink(state),
        tiers: ownProps.tiers,
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getQuittances,
    postPayReceipt: ({email, contract, receipt, query}) => postPayReceipt({email, contract, receipt, query}),
    postLogAction: ({entity, type, quittance}) => logAction({entity, type, quittance})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getQuittances, postPayReceipt, postLogAction} = dispatchProps
    const {uri, uri_pay, tiers} = stateProps

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => getQuittances({ query: _.replace(uri, '{id}', ownProps.contractId) }),
        postPayReceipt: ({ email, contract, receipt }) =>
            postPayReceipt({
                email: tiers.email,
                contract,
                receipt,
                query: uri_pay,
            }),
        postLogActionEditDocument: (quittance) =>
            postLogAction({
                entity: null,
                type: 'CLICK_EDIT_DOCUMENT',
                quittance: quittance,
                tiers: tiers.id,
            }),
    };
}

const QuittancesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Quittances)

export default QuittancesContainer
