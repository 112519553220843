import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import {
    editReclamation,
    EDIT_RECLAMATION,
    getReclamation,
    RECLAMATION,
} from '../../../../../../redux/actions/app/reclamation/reclamation.actions';
import { getTiers, TIERS } from '../../../../../../redux/actions/app/tiers/tiers.actions';
import { getContractData } from '../../../../../../redux/selectors/contract/contract.selectors';
import {
    complainEditLink,
    complainGetLink, contractDefaultGetLink,
    tiersGetLink,
} from '../../../../../../redux/selectors/links/links.selectors';
import { getReclamationData } from '../../../../../../redux/selectors/reclamation/reclamation.selectors';
import { getErrorEntity, getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent';
import loader from '../../../../../Commun/Spinner/Loader/Loader';
import ReclamationEdit from './ReclamationEdit';
import validate from './validate';
import {getContract} from "../../../../../../redux/actions/app/contracts/contracts.actions";

const mapStateToProps = (state, ownProps) => {
    const { id, idc, ids } = ownProps.match.params;
    const complain = getReclamationData(state);

    return {
        id,
        idc,
        ids,
        uriTiers: tiersGetLink(state),
        uriContract: contractDefaultGetLink(state),
        uriComplain: complainGetLink(state),
        queryEdit: complainEditLink(state),
        contract: getContractData(state),
        complain,
        loading: getLoadingEntity(state, EDIT_RECLAMATION),
        loaded: getLoadingEntity(state, TIERS) === false && getLoadingEntity(state, RECLAMATION) === false,
        error: getErrorEntity(state, TIERS),
        initialValues: {
            date_reception: moment(),
            tiers: id,
            contract: idc || null,
            sinistre: ids || null,
            description: '',
            niveau_recla: '1',
        },
    };
};

const mapDispatchToProps = {
    getTiers,
    editReclamation,
    getReclamation,
    getContract
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uriTiers, uriComplain, id, idc, queryEdit } = stateProps;
    const { editReclamation, getTiers, getReclamation } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => {
            getTiers({ query: _.replace(uriTiers, '{id}', id) });
            getReclamation({ query: _.replace(_.replace(uriComplain, '{id}', id), '{ids}', idc) });
        },
        onSubmit: (form) =>
            editReclamation({
                query: _.replace(_.replace(queryEdit, '{id}', id), '{ids}', idc),
                form,
            }),
    };
};

const ReclamationEditContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'reclamation-edit',
        validate,
    }),
    loader({ ErrorIndicator: ErrorHttpComponent }),
)(ReclamationEdit);

export default ReclamationEditContainer;
