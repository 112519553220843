import {createSelector} from 'reselect'
import _ from 'lodash'
import {getTiersBelairId} from "../tiers/tiers.selectors";

const idUrl = () => {
    let url = window.location.pathname
    return url.substring(url.lastIndexOf('/') + 1)
}

const idBetweenSlash = () => {
    let url = window.location.pathname
    return url.split('/')[2]
}

const tiersSelector = (state) => getTiersBelairId(state)

/* Links */
const linksSelector = state => state.links.links

export const userGetLink = createSelector(
    linksSelector,
    links => links['user.get'].uri
)

export const userGetNbTaches = createSelector(
    linksSelector,
    links => links['user.get_nb_taches'].uri
)

export const userLogoutLink = createSelector(
    linksSelector,
    links => links['user.logout'].uri
)

export const userWalletExtractionLink = createSelector(
    linksSelector,
    links => links['user.wallet.extraction'].uri
)

export const userWalletTransfertLink = createSelector(
    linksSelector,
    links => links['user.wallet.transfert'].uri
)

export const taskCheckdocGetLink = createSelector(
    linksSelector,
    links => links['tasks.checkdoc.get'].uri
)

export const tasksLink = createSelector(
    linksSelector,
    links => links['tasks.index'].uri
)

export const taskDetailGetLink = createSelector(
    linksSelector,
    links => links['tasks.get'].uri
)

export const validateDocumentGetLink = createSelector(
    linksSelector,
    links => links['tasks.document.validate'].uri
)

export const updateInsatisfactionFeedbackLink = createSelector(
    linksSelector,
    links => links['tasks.feedback.insatisfaction'].uri
)

export const FaqLink = createSelector(
    linksSelector,
    links => links['conseillers.faq'].uri
)

export const ConseillerExterneFaqLink = createSelector(
    linksSelector,
    links => links['conseillers.externe.faq'].uri
)

export const ConseillerFaqLink = createSelector(
    linksSelector,
    links => links['conseillers.faq'].uri
)

export const DocumentsUtilesLink = createSelector(
    linksSelector,
    links => links['conseillers.documents'].uri
)

export const DocumentationLink = createSelector(
    linksSelector,
    links => links['documents.dg'].uri
)

export const contractGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.get'].uri, '{id}', id)
)

export const contractDefaultGetLink = createSelector(
    linksSelector,
    links => links['contracts.get'].uri
)

export const tarifContractGetLink = createSelector(
    linksSelector,
    links => links['contracts.tarif_reactive'].uri
)

export const contractsTiersDefaultGetLink = createSelector(
    linksSelector,
    links => links['tiers.contracts'].uri
)

export const voucherTiersGetLink = createSelector(
    linksSelector,
    links => links['tiers.voucher.update'].uri
)

export const contractsTiersGetLink = createSelector(
    linksSelector,
    links => links['tiers.contracts'].uri
)

export const searchGetLink = createSelector(
    linksSelector,
    links => links['search'].uri
)

export const searchContractForIsicomLink = createSelector(
    tiersSelector,
    linksSelector,
    (id, links) => links['contracts'].uri + '?search=' + id
)

export const searchForIsicomLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => links['contracts'].uri + '?search='
)

export const tiersGetLink = createSelector(
    linksSelector,
    links => links['tiers.get'].uri
)

export const documentsGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.documents'].uri, '{id}', id)
)

export const antecedentsGetLink = createSelector(
    linksSelector,
    links => links['contracts.antecedents'].uri
)

export const antecedentsPeriodDetailGetLink = createSelector(
    linksSelector,
    links => links['contracts.antecedents.period'].uri
)

export const antecedentsPeriodUpdateLink = createSelector(
    linksSelector,
    links => links['contracts.antecedents.period.update'].uri
)


export const antecedentsCreateLink = createSelector(
    linksSelector,
    links => links['contracts.antecedents.create'].uri
)


export const documentsWithoutIdGetLink = createSelector(
    linksSelector,
    links => links['contracts.documents'].uri
)

export const accidentsGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['tiers.accidents'].uri, '{id}', id)
)

export const accidentsIntervenantGetLink = createSelector(
    linksSelector,
    links => links['tiers.intervenant.accidents'].uri
)

export const accidentsGetLinkWithoutIdUrl = createSelector(
    linksSelector,
    links => links['tiers.accidents'].uri
)

export const accidentsContractGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.accidents'].uri, '{id}', id)
)

export const flotteAccidentsContractGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.accidents.flotte'].uri, '{id}', id)
)

export const accidentsContractGetLinkWithoutIdUrl = createSelector(
    linksSelector,
    links => links['contracts.accidents'].uri
)

export const accidentsRejectContractGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.reject.post'].uri
)

export const postUpdateAccidentComment = createSelector(
    linksSelector,
    (links) => links['contracts.accident.comments.update'].uri
)

export const updateAccidentRepairerGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.repairer.update'].uri
)

export const updateAccidentCompanyGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.company.update'].uri
)

export const updateAccidentAttentePVGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.attentePV.update'].uri
)

export const updateAccidentStatusGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.status.update'].uri
)

export const updateAccidentGarantieGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.garantie.update'].uri
)

export const AccidentDefineAsCoveredGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.define.covered'].uri
)

export const AccidentVehicleTranferGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.vehicle.transfer'].uri
)

export const AccidentDecesGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.deces'].uri
)

export const updateAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.update'].uri
)

export const documentsNeededAccidentGet = createSelector(
    linksSelector,
    links => links['documents.needed.accident.get'].uri
)

export const postUpdateEmailCompany = createSelector(
    linksSelector,
    (links) => links['contracts.accident.mailCompany.update'].uri
)

export const activitiesContractGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.activity'].uri, '{id}', id)
)

export const contractsActivityGetLink = createSelector(
    linksSelector,
    links => links['contracts.activity'].uri
)

export const quittancesGetLink = createSelector(
    linksSelector,
    links => links['contracts.receipts'].uri
)

export const quittanceDetailGetLink = createSelector(
    linksSelector,
    links => links['receipts.get'].uri
)

export const activityGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['customer.activitylogs'].uri, '{id}', id)
)

export const tiersCreateAccountLink = createSelector(
    linksSelector,
    (links) => links['customer.create'].uri
)

export const tiersDeleteAccountLink = createSelector(
    linksSelector,
    (links) => links['customer.delete'].uri
)

export const updateRefundRepairerAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.refund.update'].uri
)

export const updatePenaltiesAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.penalties.update'].uri
)

export const deletePenaltiesAccidentGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.penalties.delete'].uri
)

export const updateOppositionsAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.oppositions.update'].uri
)

export const deleteOppositionsAccidentGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.oppositions.delete'].uri
)

export const postProcessingPVAccidentGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.pv'].uri
)

export const postAnalyseFraudeGetLink = createSelector(
    linksSelector,
    links => links['tasks.analyse_fraude'].uri
)

export const postTaskFluxMailGetLink = createSelector(
    linksSelector,
    links => links['tasks.flux_mail'].uri
)

export const payDepositLink = createSelector(
    linksSelector,
    (links) => links['pay.deposit'].uri
)

export const amendmentChangeIbanGetLink = createSelector(
    linksSelector,
    links => links['amendment.change_iban'].uri
)

export const amendmentHivernageGetLink = createSelector(
    linksSelector,
    links => links['amendment.hivernage'].uri
)

export const amendmentFinHivernageGetLink = createSelector(
    linksSelector,
    links => links['amendment.fin_hivernage'].uri
)
export const amendmentChangeIbanAccidentGetLink = createSelector(
    linksSelector,
    links => links['amendment.change_iban_accident'].uri
)

export const amendmentChangeAddressGetLink = createSelector(
    linksSelector,
    links => links['amendment.change_address'].uri
)

export const changePasswordLink = createSelector(
    idBetweenSlash,
    linksSelector,
    (id, links) => _.replace(links['customer.change_password'].uri, '{id}', id)
)

export const changePersonalInfosLink = createSelector(
    linksSelector,
    links => links['amendment.change_contact'].uri
)

export const accidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accidents.get'].uri
)

export const activitiesAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.activity'].uri
)

export const activitiesTiersGetLink = createSelector(
    linksSelector,
    (links) => links['tiers.activity'].uri
)

export const taskReassignGetLink = createSelector(
    linksSelector,
    links => links['tasks.reassign'].uri
)

export const taskShiftDateGetLink = createSelector(
    linksSelector,
    links => links['tasks.date.shift'].uri
)

export const postActivityLink = createSelector(
    linksSelector,
    (links) => links['contracts.activity.post'].uri
)

export const postAccidentActivityLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.activity.post'].uri
)

export const postTiersActivityLink = createSelector(
    linksSelector,
    (links) => links['tiers.activity.post'].uri
)

export const postChangeSituationContractLink = createSelector(
    linksSelector,
    (links) => links['contracts.validate'].uri
)

export const notesContractGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.notes'].uri, '{id}', id)
)

export const notesAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.notes'].uri
)

export const notesTiersGetLink = createSelector(
    linksSelector,
    (links) => links['tiers.notes'].uri
)

export const updateNotesGetLink = createSelector(
    linksSelector,
    (links) => links['notes.update'].uri
)

export const postNoteContractGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.note.create'].uri
)

export const postNoteAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.note.create'].uri
)

export const postNoteUpdateGetLink = createSelector(
    linksSelector,
    (links) => links['note.update'].uri
)

export const postNoteDeleteGetLink = createSelector(
    linksSelector,
    (links) => links['note.delete'].uri
)

export const piecesContractGetLink = createSelector(
    idUrl,
    linksSelector,
    (id, links) => _.replace(links['contracts.pieces'].uri, '{id}', id)
)

export const piecesContractWithoutIdGetLink = createSelector(
    linksSelector,
    links => links['contracts.pieces'].uri
)

export const deactivateContractLoyaltyCodeGetLink = createSelector(
    linksSelector,
    links => links['contracts.loyaltyCode.deactivate'].uri
)

export const informationUpdateContractLink = createSelector(
    linksSelector,
    links => links['contracts.informations.update'].uri
)

export const effectiveDateUpdateContractLink = createSelector(
    linksSelector,
    links => links['contracts.effectiveDate.update'].uri
)

export const civilityUpdateContractLink = createSelector(
    linksSelector,
    links => links['contracts.civility.update'].uri
)

export const SendMailPayReceiptLink = createSelector(
    linksSelector,
    (links) => links['pay.mail'].uri
)

export const LogActionLink = createSelector(
    linksSelector,
    (links) => links['user.log'].uri
)

export const virementsGetLink = createSelector(
    linksSelector,
    (links) => links['bank_transfer.index'].uri
)

export const virementGetLink = createSelector(
    linksSelector,
    (links) => links['bank_transfer.get'].uri
)

export const virementCreateLink = createSelector(
    linksSelector,
    links => links['bank_transfer.create'].uri
)

export const validateVirementGetLink = createSelector(
    linksSelector,
    (links) => links['bank_transfer.validate'].uri
)

export const postContractDocumentUpdate = createSelector(
    linksSelector,
    links => links['contract.document.update'].uri
)

export const postContractDocumentDelete = createSelector(
    linksSelector,
    links => links['contract.document.delete'].uri
)

export const ActivityFilesGetLink = createSelector(
    linksSelector,
    links => links['activity.files'].uri
)

export const postDocumentTaskDeleteGetLink = createSelector(
    linksSelector,
    links => links['tasks.file.delete'].uri
)

/* Email */
export const emailLibrePostLinks = createSelector(
    linksSelector,
    links => links['email.send'].uri
)

export const emailPMQPostLinks = createSelector(
    linksSelector,
    links => links['email.pmq'].uri
)

export const postEditHamonGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.hamon.update'].uri
)

export const postRemoveHamonGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.hamon.remove'].uri
)

export const refuseVirementProdGetLink = createSelector(
    linksSelector,
    (links) => links['bank_transfer.refuse'].uri
)

export const validateVirementsProdGetLink = createSelector(
    linksSelector,
    (links) => links['bank_transfer.validate.list'].uri
)
/* Statistiques */
export const getStatsLinks = createSelector(
    linksSelector,
    links => links['stats'].uri
)

export const InsurersGetLink = createSelector(
    linksSelector,
    links => links['insurer.get'].uri
)

export const closeActivityGetLink = createSelector(
    linksSelector,
    links => links['activities.terminate'].uri
)

export const transferActivityGetLink = createSelector(
    linksSelector,
    links => links['activities.transfer'].uri
)

export const tiersProductsGetLink = createSelector(
    linksSelector,
    links => links['tiers.products'].uri
)

export const tiersProductGetLink = createSelector(
    linksSelector,
    links => links['tiers.product'].uri
)

export const tiersProductEditGetLink = createSelector(
    linksSelector,
    links => links['tiers.product.edit'].uri
)

export const tiersApporteurUpdateGetLink = createSelector(
    linksSelector,
    links => links['tiers.update.apporteur'].uri
)

export const tiersApporteurSyncGetLink = createSelector(
    linksSelector,
    links => links['tiers.sync_apporteur'].uri
)

export const correspondantsGetLink = createSelector(
    linksSelector,
    links => links['tiers.correspondants'].uri
)

export const correspondantGetLink = createSelector(
    linksSelector,
    links => links['tiers.correspondent.get'].uri
)

export const createCorrespondantsGetLink = createSelector(
    linksSelector,
    links => links['tiers.correspondent.create'].uri
)

export const updateCorrespondantsGetLink = createSelector(
    linksSelector,
    links => links['tiers.correspondent.update'].uri
)

export const deleteCorrespondantsGetLink = createSelector(
    linksSelector,
    links => links['tiers.correspondent.delete'].uri
)

export const ThirdPartyPayerGetLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.get'].uri
)

export const updateThirdPartyPayerGetLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.update'].uri
)

export const deleteThirdPartyPayerGetLink = createSelector(
    linksSelector,
    links => links['contracts.third_party_payer.delete'].uri
)

export const addAccidentExpertGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.expert.add'].uri
)

// export const addAccidentEDAGetLink = createSelector(
//     linksSelector,
//     (links) => links['contracts.accident.eda.add'].uri
// )
// contracts.accident.intervenant.add
export const addAccidentIntervenantGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.intervenant.add'].uri
)

export const deleteAccidentExpertGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.expert.delete'].uri
)

export const addExpertReportAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.expert.report.add'].uri
)

export const deleteExpertReportAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.expert.report.delete'].uri
)

export const commerciauxGetLink = createSelector(
    linksSelector,
    links => links['representatives'].uri
)

export const apporteursGetLink = createSelector(
    linksSelector,
    links => links['tiers.apporteurs'].uri
)

export const statsGetLink = createSelector(
    linksSelector,
    links => links['tiers.stats'].uri
)

export const dashboardGetLink = createSelector(
    linksSelector,
    links => links['dashboard.managers'].uri
)

export const dashboardTaskDetailGetLink = createSelector(
    linksSelector,
    links => links['dashboard.managers.task.detail'].uri
)

export const dashboardMoreTaskDetailGetLink = createSelector(
    linksSelector,
    links => links['dashboard.managers.task.detail.more'].uri
)

export const dashboardPaniereDetailGetLink = createSelector(
    linksSelector,
    links => links['dashboard.managers.paniere.detail'].uri
)

export const dashboardTeleventeConseillerGetLink = createSelector(
    linksSelector,
    links => links['dashboard.televente.conseiller'].uri
)

export const dashboardTeleventeGetLink = createSelector(
    linksSelector,
    links => links['dashboard.televente.tasks'].uri
)

export const dashboardTeleventeTaskDetailsGetLink = createSelector(
    linksSelector,
    links => links['dashboard.televente.task.detail'].uri
)

export const dashboardTeleventeGestionnairesGetLink = createSelector(
    linksSelector,
    links => links['dashboard.televente.gestionnaires'].uri
)

export const dashboardTeleventeExtractionGetLink = createSelector(
    linksSelector,
    links => links['dashboard.televente.gestionnaire.extraction'].uri
)

export const getFicheLink = createSelector(
    linksSelector,
    links => links['fiches'].uri
)

/* Complain */
export const complainEditDocumentLink = createSelector(
    linksSelector,
    links => links['complain.edit_document'].uri
)

export const complainPostLink = createSelector(
    linksSelector,
    links => links['complain.create'].uri
)


export const complainEditLink = createSelector(
    linksSelector,
    links => links['complain.edit'].uri
)

export const complainGetLink = createSelector(
    linksSelector,
    links => links['complain.get'].uri
)

export const complainGetAllLink = createSelector(
    linksSelector,
    links => links['complain.get_all'].uri
)

export const complainUpdateLink = createSelector(
    linksSelector,
    links => links['complain.update'].uri
)

export const complainTransfertLink = createSelector(
    linksSelector,
    links => links['complain.transfer'].uri
)

export const complainActivityPostLink = createSelector(
    linksSelector,
    links => links['complain.activity.post'].uri
)

export const complainActivityLink = createSelector(
    linksSelector,
    links => links['complain.activity'].uri
)

export const complainReceiptLink = createSelector(
    linksSelector,
    links => links['complain.receipt'].uri
)

export const complainCloseLink = createSelector(
    linksSelector,
    links => links['complain.close'].uri
)

export const complainPendingeLink = createSelector(
    linksSelector,
    links => links['complain.pending'].uri
)

export const dateSejourLink = createSelector(
    linksSelector,
    links => links['contracts.date_sejour'].uri
)

export const driverNotMinorLink = createSelector(
    linksSelector,
    links => links['contracts.driver_not_minor'].uri
)

export const CompanysGetLink = createSelector(
    linksSelector,
    links => links['company.get'].uri
)

export const ReversementGetLink = createSelector(
    linksSelector,
    links => links['compta.reversement'].uri
)

export const CartesVertesComptaGetLink = createSelector(
    linksSelector,
    links => links['compta.cartes_vertes'].uri
)

export const BordereauxGetLink = createSelector(
    linksSelector,
    links => links['commercial.bordereaux'].uri
)

export const getFleetLink = createSelector(
    linksSelector,
    links => links['fleet'].uri
)

export const getFleetGetLink = createSelector(
    linksSelector,
    links => links['fleet.get'].uri
)

export const getFleetDeleteLink = createSelector(
    linksSelector,
    links => links['fleet.delete'].uri
)

export const getFleetVehiclesDeleteLink = createSelector(
    linksSelector,
    links => links['fleet.vehicles.delete'].uri
)

export const getFleetAddLink = createSelector(
    linksSelector,
    links => links['fleet.add'].uri
)

export const getCommissionsLink = createSelector(
    linksSelector,
    links => links['commissions'].uri
)

export const postRegulationDetailAccidentGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.regulation.detail'].uri
)

export const updateAccidentResponsabiliteGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.responsability.update'].uri
)

export const updateAccidentAccessoiresGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.accessoires.update'].uri
)

export const updateAccidentByTaskGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.update.task'].uri
)

export const deleteAccidentAccessoiresGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.accessoires.delete'].uri
)

export const updateAccidentConducteurGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.conducteur.update'].uri
)

export const changeAccidentExpertVisitGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.accident.expert.visit.change'].uri
)

export const updatePremiumLink = createSelector(
    linksSelector,
    links => links['tiers.update_premium'].uri
)

/***** FLYERS ******/
export const getFlyersLink = createSelector(
    linksSelector,
    links => links['flyers'].uri
)
export const getFlyersOrderLink = createSelector(
    linksSelector,
    links => links['flyer.order'].uri
)

export const signaturesGetLink = createSelector(
    linksSelector,
    links => links['contracts.signatures'].uri
)

export const sendSignatureGetLink = createSelector(
    linksSelector,
    links => links['contracts.send_sign'].uri
)

export const getUsersBuLink = createSelector(
    linksSelector,
    links => links['users_bu'].uri
)

export const navigationActivitiesGetLink = createSelector(
    linksSelector,
    links => links['tiers.navigation.activities'].uri
)

export const navigationContractGetLink = createSelector(
    linksSelector,
    links => links['tiers.contracts.get.navigation'].uri
)

export const getUpdateUserBUPassword = createSelector(
    linksSelector,
    links => links['update.users_bu_password'].uri
)

export const createTiersGetLink = createSelector(
    linksSelector,
    links => links['tiers.create'].uri
)

export const updateTiersGetLink = createSelector(
    linksSelector,
    links => links['tiers.update'].uri
)

export const rapportBcaGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.bca'].uri
)

export const casIdaLink = createSelector(
    linksSelector,
    links => links['contracts.accident.ida'].uri
)

export const accessoiresGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.accessoires'].uri
)

export const accessoireDetailGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.accessoires.get'].uri
)

export const deleteRepairerGetLink = createSelector(
    linksSelector,
    links => links['contracts.accident.repairer.delete'].uri
)

export const postBcaResendLink = createSelector(
    linksSelector,
    links => links['contracts.accident.bca.resend'].uri
)

export const garageGetLink = createSelector(
    linksSelector,
    (links) => links['garage.get'].uri
)

export const feedbackGetLink = createSelector(
    linksSelector,
    (links) => links['feedback.get.last'].uri
)

export const giftCardsGetLink = createSelector(
    linksSelector,
    (links) => links['tiers.contracts.giftcards.get'].uri
)

export const giftCardResendGetLink = createSelector(
    linksSelector,
    (links) => links['contracts.giftcards.resend'].uri
)

/***** QUOTES *****/
export const getQuoteLink = createSelector(
    linksSelector,
    links => links['quotes.get'].uri
)
export const cancelQuoteLink = createSelector(
    linksSelector,
    links => links['quotes.cancel'].uri
)
export const getQuotesLink = createSelector(
    linksSelector,
    links => links['quotes'].uri
)

export const parrainagesLink = createSelector(
    linksSelector,
    links => links['tiers.filleuls'].uri
)

export const versionningLink = createSelector(
    linksSelector,
    links => links['contract.versionning'].uri
)

export const validateVersionningLink = createSelector(
    linksSelector,
    links => links['contract.versionning.validate'].uri
)

export const deleteVersionningLink = createSelector(
    linksSelector,
    links => links['contract.versionning.delete'].uri
)

export const changeFractionnementLink = createSelector(
    linksSelector,
    links => links['contract.fractionnement.update'].uri
)

export const changeBirthInfosTiersLink = createSelector(
    linksSelector,
    links => links['tiers.birth.update'].uri
)

/***** DEROGATIONS *****/
export const getDerogationsLink = createSelector(
    linksSelector,
    links => links['derogations'].uri
)
export const createDerogationLink = createSelector(
    linksSelector,
    links => links['derogations.create'].uri
)
export const deleteDerogationLink = createSelector(
    linksSelector,
    links => links['derogations.delete'].uri
)

export const settingsLink = createSelector(
    linksSelector,
    links => links['rules.setting.get'].uri
)

export const updateSettingsLink = createSelector(
    linksSelector,
    links => links['rules.setting.update'].uri
)

export const postManageTracfinGetLink = createSelector(
    linksSelector,
    links => links['tiers.tracfin.task'].uri
)
export const postTracfinGetLink = createSelector(
    linksSelector,
    links => links['tiers.tracfin.validation'].uri
)

export const getStatutDematLink = createSelector(
    linksSelector,
    links => links['user.updateDematPreferences'].uri
)

export const confirmationResilHamonGetLink = createSelector(
    linksSelector,
    links => links['contracts.hamon.update_resil'].uri
)

export const createFidelisationLink = createSelector(
    linksSelector,
    links => links['contract.fidelisation.create'].uri
)

export const updateFidelisationLink = createSelector(
    linksSelector,
    links => links['contract.fidelisation.edit'].uri
)

export const getFidelisationsLink = createSelector(
    linksSelector,
    links => links['contract.fidelisation.all'].uri
)


export const getFidelisationDetailLink = createSelector(
    linksSelector,
    links => links['contract.fidelisation.get'].uri
)
