import {createSelector} from 'reselect'
import _ from "lodash";
import moment from "moment";

const commissionsSelector = state => state.commissions

export const getDataCommissions = createSelector(
    commissionsSelector,
    commissions => _.groupBy(commissions.data, item => moment(item.startDate, 'YYYY-MM-DD').year())
)

export const getDadsCommissions = createSelector(
    commissionsSelector,
    commissions => commissions.dads
)
