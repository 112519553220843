import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CONTRACT, getContract } from '../../../../../redux/actions/app/contracts/contracts.actions';
import { getQuittances, QUITTANCES } from '../../../../../redux/actions/app/quittances/quittances.actions';
import { getContractData, getIsWarkamContract } from '../../../../../redux/selectors/contract/contract.selectors';
import { contractDefaultGetLink, quittancesGetLink } from '../../../../../redux/selectors/links/links.selectors';
import { getQuittancesData } from '../../../../../redux/selectors/quittances/quittances.selectors';
import { getErrorEntity, getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import loader from '../../../../Commun/Spinner/Loader/Loader';
import AvenantSimple from './AvenantSimple';

const mapStateToProps = (state) => {
    return {
        contract: getContractData(state),
        quittances: getQuittancesData(state),
        quittanceUri: quittancesGetLink(state, CONTRACT),

        query: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false && getLoadingEntity(state, QUITTANCES) === false,
        error: getErrorEntity(state, CONTRACT) || getErrorEntity(state, QUITTANCES),
        isWakam: getIsWarkamContract(state),
    };
};

const mapDispatchToProps = {
    getContract,
    getQuittances,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { id } = ownProps.match.params;
    const { query, quittanceUri } = stateProps;
    const { getContract, getQuittances } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => {
            getContract({ query: _.replace(query, '{id}', id) });
            getQuittances({ query: _.replace(quittanceUri, '{id}', id) });
        },
    };
};

const AvenantSimpleContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
)(AvenantSimple);

export default AvenantSimpleContainer;
