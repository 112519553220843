import React from 'react';
import Spinner from "../../../../../Commun/Spinner/Spinner";

const ComptePaddock = (props) => {
    const {comptes, getTiersBUMdp, queryMDP, loading} = props

    return (
        <div className={'bloc-info bloc-documents'}>
            <h2>Compte utilisateur Espace Professionnel</h2>

            {
                comptes.map((c, i) => (
                    <div key={i}>
                        {i > 0 && <hr/>}
                        <div className={'content row'}>
                            <div className={'col-5 left-content'}>
                                <p className={'text-left'}>Email : <span className={'font-weight-bold'}>{c.email}</span>
                                </p>
                                <p className={'text-left'}>Identifiant : <span
                                    className={'font-weight-bold'}>{c.username}</span></p>
                            </div>
                            <div className={'col-7 right-content'}>
                                {loading ? <Spinner/> : <span className={'btn btn-primary btn-small'}
                                                              onClick={() => getTiersBUMdp({
                                                                  query: queryMDP,
                                                                  id: c.id
                                                              })}>Générer un mot de passe</span>}
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
    );
};

export default ComptePaddock;
