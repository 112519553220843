import React, {Fragment, useState} from 'react'
import _ from "lodash";
import {Link} from "react-router-dom";
import CallToAction from "../../../../../Commun/Button/CallToAction/CallToAction";

const InfoCourtier = (props) => {
    const {postSubmitUpdatePremium, tiers, permissions} = props
    const [actionDone, setActionDone] = useState(false)

    const handleClick = (action) => {
        postSubmitUpdatePremium({
            type: _.includes(tiers.type, '7') ? 'courtier' : 'concessionnaire',
            action: action
        })
        setActionDone(true)
    }

    return (
        <div className={'bloc-info bloc-souscripteur'}>
            <h2>Informations de l'apporteur</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Raison Sociale :</p>
                    <p>Numero/Code GIE :</p>
                    <p>Premium :</p>
                    {tiers.courtier.belair_id && <p>Courtier de rattachement :</p>}
                    <p>Adresse :</p>
                    {tiers.address_street_2 && <p>&nbsp;</p>}
                    <p>Code Postal - Ville :</p>
                    <p>Téléphone :</p>
                    <p>Email :</p>
                    <p>IBAN :</p>
                    <p>Titulaire du compte :</p>
                    <p>Strate :</p>
                    <p>Réseau Commercial :</p>
                    {tiers.libelle_reseau && <p>Libellé réseau :</p>}
                    <p>Type activité :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{tiers.name}</p>
                    <p>{tiers.codeGie}</p>
                    <p>{
                        tiers.premium
                            ? <Fragment>
                                {_.includes(tiers.type, '7') ? 'Courtier Premium' : 'Concess Premium'}
                                {_.includes(permissions, 'update status premium') && <CallToAction
                                    url={'#'}
                                    action={'edit-note'}
                                    toolText={'Rétrograder le statut'}
                                    onClick={() => {
                                        handleClick('delete')
                                    }}
                                    disabled={actionDone}
                                />}
                            </Fragment>
                            : <Fragment>
                                <span>-</span>
                                {_.includes(permissions, 'update status premium') && <CallToAction
                                    url={'#'}
                                    action={'edit-note'}
                                    toolText={'Passer le courtier/concess en Premium'}
                                    onClick={() => {
                                        handleClick('add')
                                    }}
                                    disabled={actionDone}
                                />}
                            </Fragment>
                    }</p>
                    {tiers.courtier.belair_id &&
                        <p><Link to={`/tiers/${tiers.courtier.belair_id}`}>{tiers.courtier.name}</Link></p>
                    }
                    <p>{tiers.address_street_1}</p> {tiers.address_street_2 && <p>{tiers.address_street_2}</p>}
                    <p>{tiers.address_postalcode} {tiers.address_city}</p>
                    <p>{tiers.address_phone || '-'}</p>
                    <p>{tiers.address_mail || '-'}</p>
                    <p>{tiers.banque.iban || '-'}</p>
                    <p>{tiers.banque.titulaire || '-'}</p>
                    <p>{tiers.strate || '-'}</p>
                    <p>{tiers.reseau_commercial || '-'}</p>
                    {tiers.libelle_reseau && <p>{tiers.libelle_reseau}</p>}
                    <p>{tiers.type_activite || '-'}</p>
                </div>
            </div>
        </div>
    )
}

export default InfoCourtier
